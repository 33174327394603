@import "../defaults.scss";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    background: rgba(255, 255, 255, 0.8);
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgb(136, 136, 136) 0px 2px 6px;
    border-radius: 4px;
    background: #fefefe;
}

.modal-header {
    padding: 9px;
    border: 1px solid rgb(216, 216, 216);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: rgb(245, 245, 245);
    color: rgb(42, 100, 149);
    font-size: 15px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
}

.modal-close {
    background-image: url($url-prefix + "/images/axd-icons.png");
    width: 16px;
    height: 16px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    margin-left: 1em;
    cursor: pointer;
}

.modal-title {
    width: 90%;
}

.modal-body {
    border: 1px solid rgb(216, 216, 216);
    border-bottom: none;
    width: 100%;
    overflow: auto;
    height: calc(100% - 37px);
    font-size: 13px;
}

.modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 10px 10px;
    text-align: center;
}

.modal-footer-actions {
    border-top: 1px solid rgb(216, 216, 216);
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 25%;
}

.overlay-transp{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    background: rgba(255, 255, 255, 0);
}


//Adjustments modal styles

.adjustment-bottomSection__fields {
    // margin-bottom: 10px;
}

.Modalgoto-icon {
    background: url($url-prefix + "/images/icon-go-orders.svg") no-repeat center center;

}

.col-gotoicon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    height: 16px;
    margin-bottom: -3px;
    cursor: pointer;
}