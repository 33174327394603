@import "./defaults.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

body,
input,
textarea,
select,
button {
    font: normal 14px/16px "Open Sans", sans-serif;
    color: #282828;
}

::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

::-webkit-scrollbar-track:vertical {
    background-clip: padding-box;
    border-left: 5px solid transparent;
    border-right: 0 solid transparent;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.1);
    }

    &:active {
        background-color: rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.14), inset -1px -1px 0 rgba(0, 0, 0, 0.07);
    }
}

::-webkit-scrollbar-track:horizontal {
    border-top: 5px solid transparent;
    border-bottom: 0 solid transparent;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
    }

    &:active {
        background-color: rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: inset -1px 1px 0 rgba(0, 0, 0, 0.14), inset 1px -1px 0 rgba(0, 0, 0, 0.07);
    }
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);

    &:hover {
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
    }

    &:active {
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.35);
    }
}

::-webkit-scrollbar-thumb:vertical {
    background-clip: padding-box;
    min-height: 28px;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 5px solid transparent;
    border-right: 0 solid transparent;
}

::-webkit-scrollbar-thumb:horizontal {
    background-clip: padding-box;
    min-width: 28px;
    border-top: 5px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
}

.container {
    padding: 5px;
}

.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.block {
    display: block;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.third-width {
    width: 33.333333%;
}

.full-height {
    height: 100%;
}

.text-area-border{
    border: 1px solid #dfdfdf !important;
}


.flex {
    display: flex;
}

.flex-1{
    flex: 1;
}

.gap-5{
    gap: 5px;
}

.gap-10{
    gap: 10px;
}

/* Vertical Center Alignment for Flex containers */
.items-center {
    align-items: center;
}

.items-flex-end {
    align-items: end;
}

/* Horizontal Center Alignment for Flex containers */
.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-around {
    justify-content: space-around;
}

.justify-flex-start {
    justify-content: flex-start;
}

.justify-flex-end {
    justify-content: flex-end;
}

.flex-col{
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.row-no-wrap {
    display: flex;
    flex-wrap: nowrap;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    &.twice-width {
        flex-grow: 2;
    }
}

.col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

/* Styles for Extra Large Screens */
.col-xl-auto {
    flex: 0 0 auto;
    width: auto;
}

.col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
}

.col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
}

.col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
}

.col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
}

.col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
}

.col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
}

.col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
}

.col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
}

.col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
}

.col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
}

/* Styles for Large Screens */
.col-lg-auto {
    flex: 0 0 auto;
    width: auto;
}

.col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
}

.col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
}

.col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
}

.col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
}

.col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
}

.col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
}

.col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
}

.col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
}

.col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
}

.col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
}

/* Styles for Medium Screens */
.col-md-auto {
    flex: 0 0 auto;
    width: auto;
}

.col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
}

.col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
}

.col-md-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
}

.col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
}

.col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
}

.col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
}

.col-md-9 {
    flex: 0 0 auto;
    width: 75%;
}

.col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
}

.col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
}

.col-md-12 {
    flex: 0 0 auto;
    width: 100%;
}

/* Styles for Small Screens */
.col-sm-auto {
    flex: 0 0 auto;
    width: auto;
}

.col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
}

.col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
}

.col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
}

.col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
}

.col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
}

.col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
}

.col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
}

.col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
}

.col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
}

.col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
}
@media (max-width: 991px) {
    .col,
    .col-1,
    .col-10,
    .col-11,
    .col-12,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-auto,
    .col-lg,
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-auto,
    .col-md,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-auto,
    .col-sm,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-auto,
    .col-xl,
    .col-xl-1,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-auto {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.h-100 {
    height: 100% !important;
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 0.25em;
}

.m-2 {
    margin: 0.5em;
}

.m-3 {
    margin: 0.75em;
}

.m-4 {
    margin: 1em;
}

.m-5 {
    margin: 1.25em;
}

.m-6 {
    margin: 1.5em;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 0.25em !important;
}

.ml-2 {
    margin-left: 0.5em !important;
}

.ml-3 {
    margin-left: 0.75em !important;
}

.ml-4 {
    margin-left: 1em;
}

.ml-5 {
    margin-left: 1.25em;
}

.ml-auto {
    margin-left: auto;
}

.ml-6 {
    margin-left: 1.5em;
}

.mr-0 {
    margin-right: 0;
}

.mr-1 {
    margin-right: 0.25em;
}

.mr-2 {
    margin-right: 0.5em;
}

.mr-3 {
    margin-right: 0.75em;
}

.mr-4 {
    margin-right: 1em;
}

.mr-5 {
    margin-right: 1.25em;
}

.mr-6 {
    margin-right: 1.5em;
}
.mr-7 {
    margin-right: 10.5em;
}

.mr-auto {
    margin-right: auto;
}

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: 0.25em;
}

.mt-2 {
    margin-top: 0.5em;
}

.mt-3 {
    margin-top: 0.75em;
}

.mt-4 {
    margin-top: 1em;
}

.mt-5 {
    margin-top: 1.25em;
}

.mt-6 {
    margin-top: 1.5em;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 0.25em;
}

.mb-2 {
    margin-bottom: 0.5em;
}

.mb-3 {
    margin-bottom: 0.75em;
}

.mb-4 {
    margin-bottom: 1em;
}

.mb-5 {
    margin-bottom: 1.25em;
}

.mb-6 {
    margin-bottom: 1.5em;
}

.p-0 {
    padding: 0;
}

.p-1 {
    padding: 0.25em;
}

.p-2 {
    padding: 0.5em;
}

.p-3 {
    padding: 0.75em;
}

.p-4 {
    padding: 1em;
}

.p-5 {
    padding: 1.25em;
}

.p-6 {
    padding: 1.5em;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 0.25em;
}

.pl-2 {
    padding-left: 0.5em;
}

.pl-3 {
    padding-left: 0.75em;
}

.pl-4 {
    padding-left: 1em;
}

.pl-5 {
    padding-left: 1.25em;
}

.pl-6 {
    padding-left: 1.5em;
}

.pr-0 {
    padding-right: 0;
}

.pr-1 {
    padding-right: 0.25em;
}

.pr-2 {
    padding-right: 0.5em;
}

.pr-3 {
    padding-right: 0.75em;
}

.pr-4 {
    padding-right: 1em;
}

.pr-5 {
    padding-right: 1.25em;
}

.pr-6 {
    padding-right: 1.5em;
}
.pr-7 {
    padding-right: 2em;
}

.pt-0 {
    padding-top: 0;
}

.pt-1 {
    padding-top: 0.25em;
}

.pt-2 {
    padding-top: 0.5em;
}

.pt-3 {
    padding-top: 0.75em;
}

.pt-4 {
    padding-top: 1em;
}

.pt-5 {
    padding-top: 1.25em;
}

.pt-6 {
    padding-top: 1.5em;
}

.pb-0 {
    padding-bottom: 0;
}

.pb-1 {
    padding-bottom: 0.25em;
}

.pb-2 {
    padding-bottom: 0.5em;
}

.pb-3 {
    padding-bottom: 0.75em;
}

.pb-4 {
    padding-bottom: 1em;
}

.pb-5 {
    padding-bottom: 1.25em;
}

.pb-6 {
    padding-bottom: 1.5em;
}

.br {
    border: 1px solid black;
}
.brtrl {
    border: 1px 1px 0px 1px solid black;
}
.brBottom {
    border: 1px solid black;
}
.no-border {
    border: none !important;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

textarea:focus,
input:focus {
    outline: none;
}

input[type="text"],
input[type="number"],
textarea {
    min-height: 22px;
    outline: 0;
    padding: 3px 6px 2px;
    font-size: 14px;
    line-height: normal;
    font-weight: 300;
    border: 1px solid $border-color;

    &:focus {
        border-color: #336699;
    }
    &::placeholder {
        // Placeholder Text Color
        color: #878484;
    }
}

textarea {
    resize: none;
    border: 1px solid $border-color;
}

.horizontal-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}

.container-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.hide {
    display: none;
}

.position-rel {
    position: relative;
}

.position-abs {
    position: absolute;
}

.button {
    font-size: 14px;
    line-height: 17px;
    color: #666;
    padding: 3px 5px;
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background-color: #f5f5f5;
    user-select: none;
    cursor: pointer;

    &:disabled {
        cursor: default;
    }
}

.btn {
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    color: #fff;
    padding: 0 5px;
    max-width: 100%;
    border-color: #1375bc;
    background-image: none;
    background-color: #3487c3;
    min-width: 75px;
    top: 0px;
    margin: 0px;
}

.btn-primary {
    background-color: $navy-blue;
    border: 1px solid rgb(44, 82, 115);
    border-radius: 3px;
    cursor: pointer;
    min-width: 60px;
    padding: 3px;
    height: 24px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    // min-width: 100px;

    &:hover:not(:disabled) {
        background-color: rgb(52, 135, 195);
        border-color: rgb(19, 117, 188);
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.btn-secondary {
    border: 1px solid rgb(44, 82, 115);
    border-radius: 3px;
    cursor: pointer;
    min-width: 60px;
    padding: 3px;
    height: 24px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;

    &:hover:not(:disabled) {
        background-color: lightblue;
        border-color: rgb(19, 117, 188);
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.btn-sm {
    background-color: rgb(51, 102, 153);
    border: 1px solid rgb(44, 82, 115);
    border-radius: 3px;
    cursor: pointer;
    min-width: 24px;
    padding: 1px 5px;
    height: 20px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;

    &:hover {
        background-color: rgb(52, 135, 195);
        border-color: rgb(19, 117, 188);
    }
}

.btn-link {
    background: none;
    border: none;
    text-decoration: underline;
    color: $text-blue;
    cursor: pointer;
    font: inherit;
    outline: 0;
}

.vertical-middle {
    display: inline-block;
    vertical-align: middle;
}

.section-separator {
    height: 1px;
    background: #efefef;
    margin: 0.75em 0;
}

/*******************************************/
/**** React Date Picker Styles Override ****/
/*******************************************/

div.react-datepicker-wrapper,
div.react-datepicker__input-container {
    display: block;

    & input {
        width: 100%;
    }
}

.react-datepicker .react-datepicker__month-read-view span,
.react-datepicker .react-datepicker__year-read-view span {
    border-width: 0.3rem !important;
    top: 7px;
}

div.react-datepicker-popper {
    z-index: 100;
}

/* Input Placeholder text color */
::-webkit-input-placeholder {
    color: #999;
}
:-moz-placeholder {
    color: #999;
}
::-moz-placeholder {
    color: #999;
}
:-ms-input-placeholder {
    color: #999;
}

.titleHeader {
    font-size: 20px;
    font-weight: 600;
    color: #999;
    margin-bottom: 20px;
}
.sub-header {
    font-size: 14px;
    font-weight: 600;
    color: #999;
    margin-bottom: 10px;
    margin-bottom: 13px;
    display: block;
    color: #999;
    text-transform: uppercase;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.pf {
    padding: 0.25em 1em 1em 1em;
}

label {
    cursor: text;
}

svg {
    border: 0px !important;
    border-radius: 0% !important;
    box-shadow: none !important;
}
.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    opacity: 0.5;
    z-index: 10000;
}
.uploadMask {
    position: absolute;
    width: 100%;
    height: 170%;
    left: 0;
    right: 0;
    top: -15px;
    bottom: 0;
    background-color: white;
    opacity: 0.5;
    z-index: 10000;
}
.divdisabled {
    pointer-events: none;
    /* for "disabled" effect */
    opacity: 0.5;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    line-height: 1.2rem !important;
}

.react-datepicker__time-list-item--disabled{
    display: none;
}

.plus-symbol {
    color: #0073c7;
    margin-bottom: -3px;
    width: 18px;
    height: 18px;
}
li:hover {
    background-color: #f5f5f5;
    border-radius: 4px;
}
.pointer {
    cursor: pointer;
}

.user-select-none{
    user-select: none;
}

.masked {
    opacity: 0.3;
    pointer-events: none;
}

.custom-fields-content textarea {
    border: 1px solid $border-color !important;
}
.icon-gmail {
    background-image: url($url-prefix + "/images/gmail.JPG") !important;
    background-repeat: no-repeat !important;
    background-size: 30px 30px !important;
    background-position: 2px 3px !important;
    padding-left: 35px !important;
    height: 35px !important;
}
.icon-gmail-small {
    background-image: url($url-prefix + "/images/gmail.JPG");
    background-repeat: no-repeat;
    background-size: 18px 16px;
    background-position: 3px 10px;
    padding-left: 35px;
    height: 35px;
}
.icon-send {
    background-image: url($url-prefix + "/images/icon-send.svg") !important;
    background-repeat: no-repeat !important;
    // background-size: 30px 30px !important;
    // background-position: 2px 3px !important;
    // padding-left: 35px !important;
    height: 35px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.float-right {
    float: right;
}
.Icon-Email-Verification-Empty {
    width: 24px;
    margin-left: 1px;

    margin-top: 5px;
    position: relative;
}
.Icon-Email-Verification {
    width: 24px;
    margin-left: 1px;
    background-image: url($url-prefix + "/images/Mail.svg");
    background-repeat: no-repeat;
    margin-top: 5px;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 11px;
        height: 11px;
        right: 0;
        border-radius: 25px;
        border: 2px solid #fff;
    }
    &.Invalid::after {
        background-color: #f70000;
    }
    &.Valid::after {
        background-color: #56c143;
    }
    &.Unknown::after {
        background-color: #4b89dc;
    }

    &.NotInitiated::after {
        background-color: #4b89dc;
    }
    &.NotEnabled::after {
        background-color: #8c8989;
    }
    &.InProgress::after {
        background-color: #4b89dc;
    }
}
.icon-duplicate-email-Empty {
    width: 24px;
    margin-left: 1px;

    margin-top: 5px;
    position: relative;
}

.icon-duplicate-email {
    width: 30px;
    margin-left: 1px;
    background-image: url($url-prefix + "/images/duplicate-email.svg");
    background-repeat: no-repeat;
    margin-top: 5px;
    position: relative;
    cursor: pointer;

    &::after {
        content: attr(data-content);
        position: absolute;
        width: auto;
        height: auto;
        min-width: 12px;
        min-height: 15px;
        padding:1px;
        right: 0;
        padding:1px;
        border-radius: 25px;
        border: 2px solid #fff;
        color: white;
        text-align: center;
        font-size: xx-small;
        line-height: initial;
    }

    &.Invalid::after {
        background-color: #f70000;
    }
}
.borderBottom {
    border-bottom: 1px solid #ddd;
    margin: 10px 0px 20px 0px;
}

.save-button {
    margin-top: 9px;
}
.salesTotals{
    height:300px;
    overflow: auto;
}
.salesOverflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.invalid {
    border-bottom: 1px solid #f44336;
}

.toggle-switch-outer
{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 25px;
    width: 35px;
    height: 18px;
    position: relative
}

.toggle-switch-inner
{
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    transition: all 0.3s ease
}

//visually distinct style for mentioned text
.highlight-mention {
    background-color: #f0f0f5;
    border-radius: 20px;
    padding: 3px 6px;
    display: inline-block;
    margin-right: 5px;
    color: #3c3c3c;

    &:hover {
        background-color: #DCDFE4;
    }
}

.icon-growth-bars {
    background-image: url($url-prefix + "/images/growth-bars-svg.svg");
    background-repeat: no-repeat;
    background-position: right;
}
.icon-coins {
    background-image: url($url-prefix + "/images/coins-svg.svg");
    background-repeat: no-repeat;
    background-position: right;
}
.icon-increase {
    background-image: url($url-prefix + "/images/increase-svg.svg");
    background-repeat: no-repeat;
    background-position: right;
}