@import "../../../defaults.scss";
@import "../../../ui.scss";


    .overlay-notifications {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100000; // Ensure a higher z-index for this popup across all pages, considering the existing elevated levels on other elements.
        background: rgba(255, 255, 255, 0.8);
        transform: translate(0, 0);
    }

.notifications90-maincontent{
    ::-webkit-scrollbar {
        width: 9px !important;
        height: 16px !important;
    }
    transform: translate(10%, 10%) !important;
    
}
.notifications75-maincontent{
    ::-webkit-scrollbar {
        width: 9px !important;
        height: 16px !important;
    }
    transform: translate(25%, 15%) !important;

}
.notifications60-maincontent{
    ::-webkit-scrollbar {
        width: 9px !important;
        height: 16px !important;
    }
    transform: translate(70%, 50%) !important;
    .html-text{
        line-height: 25px !important
    }
}

.container-footer-notification {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: white;
    // p {
    //     text-align: center;
    //     margin: 5px 0 0 0;
    //   }
  }
  .box-notification {
    flex: 1;
    display: flex;
    justify-content: center;
  }


  
  .box-notification:first-child > span { margin-right: auto; }
  .box-notification:last-child  > span { margin-left: auto;  }
  
  /* non-essential */
  .box-notification {
    align-items: center;
    height: 40px;
  }
 


// .notifications25-maincontent{
   
 
//     // ::-webkit-scrollbar {
//     //     margin-left: auto;
//     //     width: 8px;
//     //     height: 0px;
//     // }
    
//     // .popup-htmlContent{
//     //     line-height: 25px;
//     // }
//     .notification-contentsection {
//         height: 70%;
//         overflow: scroll;
//     }
//     .notification-footersection{
//         left: 35%;
// }
// .popup-textcontent{
//     display: flex;
//     // height: auto;
//     width: auto;
//  text-align: center;
// }

// .notification-contentsection{
//     width: 90%;
//     height: 70%;
//     overflow: scroll;
// }
// }
//     .overlay {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         z-index: 100;
//         background: rgba(255, 255, 255, 0.8);
//     }
  .arrow-iconborder {
    height: 30px;
    width: 30px;
    background-color: #8e9497;
    border-radius: 80%;
    display: inline-block;
  }
//   .notification-imagecontent {
//     width: 90%;
//     height: auto; 
// }


.header-content {
    padding: 9px;
    border: 1px solid rgb(216, 216, 216);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    // background-color:ffff !important;
    color: rgb(42, 100, 149);
    font-size: 15px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%
}
.modal-close {
    background-image: url($url-prefix + "/images/axd-icons.png");
    width: 16px;
    height: 16px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    margin-left: 1em;
    cursor: pointer;
}

// .buttonItems{
// display: flex;
//     justify-content: center;
//     align-items: center;
//     .btn-blue-border {
//         width: 220px !important;
//     }
// }


.maincontent{
    padding:1em 0em 1em 1em
}
.bobox-notificationx{
    text-align: center;
    line-height: 3;
  }
