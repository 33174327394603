$url-prefix: "";

//$text-blue: #0073c7;  // ice Blue
//$text-blue: #4B89DC;
//$text-blue: #2d6cc1;
//$text-blue: #006ce5;
// $btn-blue: #4a88db;
// $btn-blue-hover: #2d6cc1;
// $btn-borderblue: #4a88db;
// $btn-borderblue-hover: #2d6cc1;
// $bg-blue: #2d6cc1;
// $border-blue: #2d6cc1;
// $text-blue: #4b89dc;
// $text-blue: #2d6cc1;

$regular-text: #231f20;
// $text-blue: #3d5dc0;
$text-blue: #4b89dc;
$text-red: #e95b6d;
$text-green: #007b00;
$navy-blue: #336699;
$header-blue: #f9fbfc; //aqua blue
$header-blue: #f9fbfc; //aqua blue
$border-color: #dfdfdf; //Gray
$row-alt-color: #fafafa; //Grid data row alternate gray color
$text-orange: #eca945;
$text-indigo: #7e9cee;
$text-pink: #ea6db5;

$label-text-color: #231f20; //Label color
$header-grey-color: #eee; //Gray

$normal-font: normal 14px/16px "Open Sans", sans-serif;
