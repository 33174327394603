@import "../defaults.scss";

.confirm-popup {
    padding: 10px;
}
.confirm-actions {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.confirm-icon {
    height: 32px;
    width: 32px;
    background-image: url($url-prefix + "/images/axd-confirm-icon.png");
}

.info-icon {
    height: 32px;
    width: 32px;
    background: url($url-prefix + "/images/icon-info.svg") no-repeat;
    background-size: 32px 32px;
}

.alert-icon {
    height: 32px;
    width: 32px;
    background-image: url($url-prefix + "/images/axd-alert-icon.png");
}

.confirm-message {
    padding-left: 10px;
}
.doNot-Show {
    position: relative;
    left: 40px;
    width: 200px;
}
.doNot-ShowCheck {
    position: relative;
    top: 1px;
}

.success-icon {
    height: 24px;
    width: 24px;
    background-image: url($url-prefix + "/images/success-check-circle-icon.png");
    background-size: 24px 24px;
    margin-left: 7px;
}
