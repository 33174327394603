@import "./App.scss";
@import "./defaults.scss";
//Icons CSS Styles
.col-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    cursor: pointer;
}
.test{

    width: 16px;
}

.edit-icon {
    background: url($url-prefix + "/images/icon-edit-orange.svg") no-repeat center center;
    // height: 16px;
}
.approval-icon {
    background: url($url-prefix + "/images/approve-icon.svg") no-repeat center center;
    background-size: 16px;
}
.edit-old-icon{
    background: url($url-prefix + "/images/page_white_edit.png") no-repeat center center;
}
.ext-link-icon{
    background: url($url-prefix + "/images/external-link.png") no-repeat center center;
}
.drag-icon{
    background: url($url-prefix + "/images/icon-drag.svg") no-repeat center;
}
.checked-icon {
    content: url($url-prefix + "/images/icon-success-white.svg");
}
.view-approve-icon {
    content: url($url-prefix + "/images/icon-view-approve.svg");
    margin-top: 4px;
    width: 25px;
    height: 25px;
}
.close-div-icon {
    content: url($url-prefix + "/images/icon-close-gray.svg");
   }
.preview-icon {
    background: url($url-prefix + "/images/icon-preview-color.svg") no-repeat center center;
    // height: 16px;
}
.mail-icon {
    background: url($url-prefix + "/images/icon-send-email.svg") no-repeat center center;
    // height: 16px;
}
.paint-icon{
    background: url($url-prefix + "/images/paint-icon.svg") no-repeat center center;
}
.close-icon {
    background-image: url($url-prefix + "/images/axd-icons.png");
    width: 16px;
    height: 16px;
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor: pointer;
    // position: relative;
    // bottom: 4px;
}
.goto-icon {
    background: url($url-prefix + "/images/icon-go-orders.svg") no-repeat center center;
    // height: 16px;
}
.delete-icon {
    background: url($url-prefix + "/images/icon-delete-orders.svg") no-repeat center center;
}
.unlink-icon{
    background: url($url-prefix + "/images/unlink-icon.svg") no-repeat center center;
}
.icon-complete {
    background: url($url-prefix + "/images/icon-complete.svg") no-repeat center center;
}
.icon-history {
    background: url($url-prefix + "/images/icon-history-fill.svg") no-repeat center center;
    // height: 16px;
}
.icon-changeorder-yellow {
    background: url($url-prefix + "/images/icon-ChangeOrder-history-yellow.svg") no-repeat center center;
    // height: 16px;
}
.icon-changeorder-green {
    background: url($url-prefix + "/images/icon-ChangeOrder-history-green.svg") no-repeat center center;
    // height: 16px;
}
.icon-link-order{
    background: url($url-prefix + "/images/broken-link.svg") no-repeat center center;
}
.mmg-icon-manager{
    background: url($url-prefix + "/images/gam-pull.png") no-repeat center center;
}
.gam-push-icon-manager{
    background: url($url-prefix + "/images/gam-push.png") no-repeat center center;
}
.bsa-push-icon-manager{
    background: url($url-prefix + "/images/bsa-push.png") no-repeat center center;
}
.bsa-pull-icon-manager{
    background: url($url-prefix + "/images/bsa-pull.png") no-repeat center center;
}

.icon-uncomplete {
    background: url($url-prefix + "/images/icon-uncomplete.svg") no-repeat center center;
}

.settings-icon{
    background: url($url-prefix +"/images/settings-icon.svg") no-repeat center center;
}
.icon-calendar {
    background: url($url-prefix + "/images/calendar.svg") no-repeat center center;
}
.edit-pencil{
    background: url($url-prefix + "/images/pencil-icon.svg") no-repeat center center;
}
.view-search-icon{
    background: url($url-prefix + "/images/view-icon-blue.svg") no-repeat center center;
}
.view-search-icon-white{
    background: url($url-prefix + "/images/view-icon-white.svg") no-repeat center center;
}
.yellow-Triangle-icon{
    background: url($url-prefix +"/images/yellow-Triangle-Icon.svg") no-repeat center center;
}
.small-newagency-contact {
    background: url($url-prefix + "/images/icon-small-newagency-contact.svg") no-repeat center center;
}

.small-newceagencycontact {
    background: url($url-prefix + "/images/icon-small-newceagency-contact.svg") no-repeat center center;
}

.small-newcompanyExpiredOrders {
    background: url($url-prefix + "/images/icon-small-company-expired-orders.svg") no-repeat center center;
}

.small-newcompanyFutureOrders {
    background: url($url-prefix + "/images/icon-small-company-future-orders.svg") no-repeat center center;
}

.small-newcompanynorders {
    background: url($url-prefix + "/images/icon-small-new-company-no-orders.svg") no-repeat center center;
}

.small-newsubcontact {
    background: url($url-prefix + "/images/icon-small-new-subcontact.svg") no-repeat center center;
}

.icon-week {
    background: url($url-prefix + "/images/icon-week-blue.svg") no-repeat center center;
}

.icon-today {
    background: url($url-prefix + "/images/icon-today-blue.svg") no-repeat center center;
}

.icon-month {
    background: url($url-prefix + "/images/icon-month-blue.svg") no-repeat center center;
}

.icon-print {
    content: url($url-prefix + "/images/icon-print.svg");
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 1em;
}
.icon-print-colored {
    content: url($url-prefix + "/images/icon-print-color.svg");
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.icon-print-pdf {
    content: url($url-prefix + "/images/pdf80.png");
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.add-icon {
    background: url($url-prefix + "/images/icon-add.svg") no-repeat center center;
    display: inline-block;
    min-width: 20px;
    height: 20px;
}
.more-icon {
    background: url($url-prefix + "/images/icon-more.svg") no-repeat right center;
    width: 58%;
    height: 53%;
}
.label-info-icon {
    background: url($url-prefix + "/images/icon-label-info.svg") no-repeat center center;
}
.label-info-icon-white {
    background: url($url-prefix + "/images/icon-label-info-white.svg") no-repeat center center;
}
.label-info-icon:hover {
    background: url($url-prefix + "/images/icon-label-info-hover.svg") no-repeat center center;
}
.error-info-icon {
    background: url($url-prefix + "/images/icon-error-info.svg") no-repeat center center;
}
.error-info-icon:hover {
    background: url($url-prefix + "/images/icon-error-info-hover.svg") no-repeat center center;
}
.warning-info-icon {
    background: url($url-prefix + "/images/icon-warning-info.svg") no-repeat center center;
}
.warning-info-icon:hover {
    background: url($url-prefix + "/images/icon-warning-info-hover.svg") no-repeat center center;
}

.btn-text-with-icon {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0 10px;
    height: 28px;
    line-height: 28px;
    cursor: pointer;
    padding: 5px;
    &:hover {
        background: #eef6fb;
        cursor: pointer;
    }
    label:hover {
        cursor: pointer;
    }
}

// Action Icons Styles
.toolbarItem {
    user-select: none;
    padding: 0px 5px 0px 4px;
    border-radius: 5px;
    margin: 3px 4px 3px 4px;
    box-sizing: border-box;
}
.toolbarItem.active {
    background-color: $border-color;
}
.toolbarItem:hover {
    background-color: #e7f1ff;
    color: $text-blue;
}
.toolbarItem .action-items {
    line-height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
// .toolbarItem .action-items:hover span { background-color: #e7f1ff !important; border-radius: 50px;}
.toolbarItem .action-items span {
    display: inline-block;
    height: 28px;
    width: 28px;
    text-align: center;
    margin-right: 1px;
}
.toolbarItem .action-items span {
    display: inline-block;
    height: 28px;
    width: 28px;
    text-align: center;
    margin-right: 1px;
}

.adcellerant {
    background: url($url-prefix + "/images/ce-Adcellerant.svg") no-repeat;
}
.toolbarItem .action-items.ai-icon-batchUpdate span {
    background: url($url-prefix + "/images/icon-bulk-edit.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-batchUpdate span {
    background: url($url-prefix + "/images/icon-bulk-edit-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-copyOrRenew span {
    background: url($url-prefix + "/images/icon-copy-or-renew.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-copyOrRenew span {
    background: url($url-prefix + "/images/icon-copy-or-renew-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-deleteContact span {
    background: url($url-prefix + "/images/icon-delete-contact.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-deleteContact span {
    background: url($url-prefix + "/images/icon-delete-contact-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-list span {
    background: url($url-prefix + "/images/icon-my-list.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-list span {
    background: url($url-prefix + "/images/icon-my-list-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-moveOrders span {
    background: url($url-prefix + "/images/icon-move-orders.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-moveOrders span {
    background: url($url-prefix + "/images/icon-move-orders-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-addContract span {
    background: url($url-prefix + "/images/icon-add-contract.svg") no-repeat center center/17px ;
}
.toolbarItem .action-items.ai-icon-removeContract span {
    background: url($url-prefix + "/images/icon-remove-contract.svg") no-repeat center center/17px;
}

.toolbarItem:hover .action-items.ai-icon-addContract span {
    background: url($url-prefix + "/images/icon-add-contract-hover.svg") no-repeat center center/17px ;
}
.toolbarItem:hover .action-items.ai-icon-removeContract span {
    background: url($url-prefix + "/images/icon-remove-contract-hover.svg") no-repeat center center/17px;
}
.toolbarItem .action-items.ai-icon-print span {
    background: url($url-prefix + "/images/icon-print.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-print span {
    background: url($url-prefix + "/images/icon-print-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-export span {
    background: url($url-prefix + "/images/icon-export.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-export span {
    background: url($url-prefix + "/images/icon-export-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-payment-transfers span {
    background: url($url-prefix + "/images/payment-icon-gray.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-payment-transfers span {
    background: url($url-prefix + "/images/payment-icon-blue.svg") no-repeat center center;
}

.toolbarItem .action-items.ai-icon-prepay span {
    background: url($url-prefix + "/images/icon-black-prepay.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-prepay span {
    background: url($url-prefix + "/images/icon-blue-prepay.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-sendEmail span {
    background: url($url-prefix + "/images/icon-send-email.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-followUp span {
    background: url($url-prefix + "/images/normal-followup.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-reminder span {
    background: url($url-prefix + "/images/reminder.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-sendEmail span {
    background: url($url-prefix + "/images/icon-send-email-hover.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-followUp span {
    background: url($url-prefix + "/images/hover-followup.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-sendData span {
    background: url($url-prefix + "/images/icon-send-data.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-sendData span {
    background: url($url-prefix + "/images/icon-send-data-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-hideData span {
    background: url($url-prefix + "/images/icon-hide-data.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-hideData span {
    background: url($url-prefix + "/images/icon-hide-data-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-unhideData span {
    background: url($url-prefix + "/images/icon-unhide-data.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-unhideData span {
    background: url($url-prefix + "/images/icon-unhide-data-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-unlinkData span {
    background: url($url-prefix + "/images/icon-unlink-data.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-unlinkData span {
    background: url($url-prefix + "/images/icon-unlink-data-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-active span {
    background: url($url-prefix + "/images/icon-active.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-active span {
    background: url($url-prefix + "/images/icon-active-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-edit span {
    background: url($url-prefix + "/images/icon-edit-white.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-edit span {
    background: url($url-prefix + "/images/icon-edit-blue.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-inactive span {
    background: url($url-prefix + "/images/icon-inactive.svg") no-repeat center center;
}
.toolbarItem:hover .action-items.ai-icon-inactive span {
    background: url($url-prefix + "/images/icon-inactive-hover.svg") no-repeat center center;
}
.toolbarItem .action-items.ai-icon-ungroup span {
    background: url($url-prefix + "/images/icon-group-ungroup.svg") no-repeat center center;
}

.toolbarItem:hover .action-items.ai-icon-ungroup span {
    background: url($url-prefix + "/images/icon-group-ungroup.svg") no-repeat center center;
}
.toolbarItem .icon-all-selected,
.toolbarItem .icon-few-selected,
.toolbarItem .icon-none-selected {
    height: 27px;
    line-height: 27px;
    padding-left: 19px;
}
.toolbarItem .icon-all-selected {
    background: url($url-prefix + "/images/icon-all-selected-hover.svg") no-repeat 0px 6px !important;
}
.toolbarItem .icon-few-selected {
    background: url($url-prefix + "/images/icon-few-selected.svg") no-repeat 0px 6px !important;
}
.toolbarItem .icon-none-selected {
    background: url($url-prefix + "/images/icon-none-selected.svg") no-repeat 0px 6px !important;
}
.toolbarItem .action-items.ai-icon-sendCancel span{
    background: url($url-prefix + "/images/SubscriptionIcons/Send Cancel.svg") no-repeat 6px 6px !important;
}

// Button Styles
.btn {
    border-radius: 0;
    border: 0;
    border-radius: 4px;
    outline: none;
}
.button {
    min-width: 100px;
    padding: 6px 20px;
    text-decoration: none;
    font-size: 15px;
    font-weight: normal;
    border: 0;
    border-radius: 3px;
    line-height: 20px;
}
.btn-blue {
    min-width: 100px;
    padding: 6px 20px;
    text-decoration: none;
    background: $text-blue;
    color: #fff;
    border: 0 !important;
    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
}
.btn:focus,
.btn:active {
    outline: none;
    border: 0;
    outline: none !important;
    box-shadow: none !important;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
}

.btn-blue-border {
    min-width: 100px;
    padding: 6px 20px;
    text-decoration: none;
    background: #fff;
    color: $text-blue;
    font-size: 15px;
    border: 1px solid $text-blue !important;
    border-radius: 3px;
    &.primary {
        color: $navy-blue;
        border: 1px solid $navy-blue !important;
    }
}

.btn-blue-border:hover {
    color: #fff;
    background: $text-blue;
    transition: background-color 1000ms linear;
    &.primary:hover {
        background: $navy-blue;
    }
}
// Subheader Columns
.col-left-subhead {
    display: flex;
    align-items: center;
}
.col-right-subhead {
    display: inline-flex;
    margin-left: auto;
}
.col-center-subhead {
    display: flex;
    margin-left: auto;
}

/* Page Heading */
.header-fixed {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 99;
}
.page-heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.page-heading .heading {
    line-height: 27px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #003466;
    margin-bottom: 4px;
}
.page-heading .actions {
    flex-grow: 1;
    text-align: right;
}
.page-heading .status {
    color: white;
    font-size: 12px;
    padding: 2px 9px;
    border-radius: 25px;
    line-height: 17px;
}

.btn-blue {
    min-width: 100px;
    padding: 6px 20px;
    text-decoration: none;
    background: $text-blue;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
}

.section-seperator {
    width: 100%;
    border: 1px solid #e2e2e2;
    margin-top: 1em;
    margin-bottom: 1.6em;
}

.section-header {
    display: flex;
    align-items: center;
    color: $text-blue;
    font-size: 18px;
}

/* Added when Dashboard Implementation */

.dash-car-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-left: 2px;
    cursor: pointer;
}

.dash-car-delete-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    margin-left: 2px;
}

.vertical-middle-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    margin-left: 2px;
}

.status-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
}

.dash-car-icon-selected {
    background-color: lightblue;
}

.dash-car-icon:hover {
    background-color: lightblue;
}

.icon-create-opport {
    background: url($url-prefix + "/images/icon-opportunitiesCreate.svg") no-repeat center 5px;
}

.icon-call {
    background: url($url-prefix + "/images/icon-call.svg") no-repeat 10px 10px;
}

.icon-go-callback {
    background: url($url-prefix + "/images/icon-go-callback.svg") no-repeat 5px 6px;
}

.icon-meetings-call {
    background: url($url-prefix + "/images/icon-meetings-call.svg") no-repeat 6px 4px;
}

.icon-opport1 {
    background: url($url-prefix + "/images/icon-opportunities1.svg") no-repeat 12px 8px;
}

.icon-opport2 {
    background: url($url-prefix + "/images/icon-opportunities2.svg") no-repeat 10px 10px;
}

a.icon-reload {
    background: url($url-prefix + "/images/icon-refresh.svg") no-repeat 6px 7px;
}

a.icon-help {
    background: url($url-prefix + "/images/icon-info-help.svg") no-repeat 6px 7px;
}

a.icon-more {
    background: url($url-prefix + "/images/icon-more.svg") no-repeat no-repeat 6px 7px;
}
.activities {
    font-size: 13px;
    line-height: 14px;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-left: 0em;
    margin-top: 2em;
}
.activitytypesicon::before {
    content: " ";
    position: absolute;
    top: 1px;
    left: -11px;
    width: 25px;
    height: 25px;
}
.activitytypesicon.icon-pos::before {
    left: 0px !important;
}
 .callback-editIcon{
    height:25px;
    width:40px
 }

.activitytypesicon.icon-position::before {
    left: 10px !important;
    top: -2px;
}

.activitytypesicon.notes::before {
    background: url($url-prefix + "/images/notesicon.svg") no-repeat 0px 0px;
}

.activitytypesicon.notes:hover::before {
    background: url($url-prefix + "/images/cenotes-hover.svg") no-repeat 0px 0px;
}

.activitytypesicon.callbacks::before {
    background: url($url-prefix + "/images/callsicon.svg") no-repeat 0px 0px;
}

.activitytypesicon.callbacks:hover::before {
    background: url($url-prefix + "/images/cecalls-hover.svg") no-repeat 0px 0px;
}


.activitytypesicon.callbacks.NotCompleted::before {
    background: url($url-prefix + "/images/callsicon.svg") no-repeat 0px 0px;
}

.activitytypesicon.callbacks.NotCompleted:hover::before{
    background: url($url-prefix + "/images/callsicon.svg") no-repeat 0px 0px;
}


.activitytypesicon.callbacks.Completed:hover::before {
    background: url($url-prefix + "/images/cecalls-hover.svg") no-repeat 0px 0px;
}

.activitytypesicon.callbacks.Completed::before {
    background: url($url-prefix + "/images/cecalls-hover.svg") no-repeat 0px 0px;
}

.activitytypesicon.Email::before {
    background: url($url-prefix + "/images/emailsicon.svg") no-repeat 0px 0px;
}

.activitytypesicon.Email:hover:before {
    background: url($url-prefix + "/images/cemail-hover.svg") no-repeat 0px 0px;
}
.activitytypesicon.TaskType::before {
    background: url($url-prefix + "/images/task_normal.svg") no-repeat 0px 0px;
}

.activitytypesicon.TaskType:hover:before {
    background: url($url-prefix + "/images/task_hover.svg") no-repeat 0px 0px;
}

.activitytypesicon.UserLoginType::before {
    background: url($url-prefix + "/images/user-check-in.svg") no-repeat 0px 0px;
}

.activitytypesicon.inboxnotifications::before {
    background: url($url-prefix + "/images/bell.svg") no-repeat 0px 0px;
}

.activitytypesicon.Proposal::before {
    background: url($url-prefix + "/images/ce-proposals.svg") no-repeat 0px 0px;
}

.activitytypesicon.Order::before {
    background: url($url-prefix + "/images/ce-orders.svg") no-repeat 0px 0px;
}

.activitytypesicon.Opportunity::before {
    background: url($url-prefix + "/images/ce-opportunities.svg") no-repeat 0px 0px;
}

.activitytypesicon.meetings::before {
    background: url($url-prefix + "/images/meetingsicon.svg") no-repeat 0px 0px;
}

.activitytypesicon.meetings:hover:before {
    background: url($url-prefix + "/images/cemeeting-hover.svg") no-repeat 0px 0px;
}

.activitytypesicon.MassEmail::before {
    background: url($url-prefix + "/images/icon-massemail-nor.svg") no-repeat 0px 0px;
}

.activitytypesicon.MassEmail:hover:before {
    background: url($url-prefix + "/images/icon-massemail-hover.svg") no-repeat 0px 0px;
}

.activitytypesicon.Letter::before {
    background: url($url-prefix + "/images/icon-letter-nor.svg") no-repeat 0px 0px;
}

.activitytypesicon.Letter:hover:before {
    background: url($url-prefix + "/images/icon-letter-hover.svg") no-repeat 0px 0px;
}
.emptyCallIcon {
    background: url($url-prefix + "/images/callback-editEmptyIcon.svg") no-repeat 0px 0px;
}
.nonEmptyCallIcon{
    background: url($url-prefix + "/images/callback-editIcon.svg") no-repeat 0px 0px;
}
.tick-green {
    background-image: url($url-prefix + "/images/icon-green-tick.svg");
    position: relative;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: 14px;
    height: 15px;
}

.tick-green-calendar {
    background-image: url($url-prefix + "/images/icon-green-tick.svg");
    position: relative;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    top: 5px;
    width: 16px;
    height: 14px;
    display: inline-block;
}

.icon-full-screen.selected {
    background: $text-blue url($url-prefix + "/images/icon-full-screen-selected.svg") no-repeat 9px 3px;
    border: 1px solid $text-blue;
}

.icon-full-screen {
    border: 1px solid #54adfd;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    vertical-align: middle;
    height: 28px;
    width: 40px;
    cursor: pointer;
    background: url($url-prefix + "/images/icon-full-screen.svg") no-repeat 9px 3px;
}

.icon-expand-full-screen {
    vertical-align: middle;
    height: 20px;
    width: 24px;
    cursor: pointer;
    background: url($url-prefix + "/images/icon-expand-full-screen.svg") no-repeat 9px 3px;
}
.icon-list-screen.selected {
    background: $text-blue url($url-prefix + "/images/icon-list-screen-selected.svg") no-repeat 9px 6px;
    border: 1px solid $text-blue;
}

.icon-expanded-view {
    border: 1px solid #54adfd;
    border-radius: 4px;
    vertical-align: middle;
    height: 28px;
    width: 35px;
    cursor: pointer;
    background: url($url-prefix + "/images/icon-expanded-view.svg") no-repeat 5px 1px;
}
.icon-expanded-view.selected {
    background: $text-blue url($url-prefix + "/images/icon-expanded-view-selected.svg") no-repeat 5px 1px;
    border: 1px solid $text-blue;
}

.icon-list-screen {
    border: 1px solid #54adfd;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    vertical-align: middle;
    height: 28px;
    width: 35px;
    cursor: pointer;
    background: url($url-prefix + "/images/icon-list-screen.svg") no-repeat 9px 6px;
}
.icon-refresh-screen {
    border: 1px solid #54adfd;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    vertical-align: middle;
    height: 28px;
    width: 35px;
    cursor: pointer;


}
.icon-calendar-screen {
    border: 1px solid #54adfd;
    border-left: none;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    vertical-align: middle;
    height: 28px;
    width: 40px;
    cursor: pointer;
    background: url($url-prefix + "/images/icon-calendar-screen.svg") no-repeat 0px -7px;
}
.icon-calendar-screen.selected {
    background: $text-blue url($url-prefix + "/images/icon-calendar-screen-selected.svg") no-repeat 0px -7px;
    border: 1px solid #4b89dc;
}
.icon-gantt-screen {
    border: 1px solid #54adfd;
    border-left: none;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    vertical-align: middle;
    height: 28px;
    width: 34px;
    cursor: pointer;
    background: url($url-prefix + "/images/icon-gantt-view.svg") no-repeat 6px 3px;
}

.icon-gantt-screen.selected {
    background: $text-blue url($url-prefix + "/images/icon-gantt-view-selected.svg") no-repeat 6px 3px;
    border: 1px solid #4b89dc;
}
.border-top {
    border-top: 1px solid $border-color;
}
.text-underline {
    :hover {
        text-decoration: underline;
    }
}

// Button section in SidePanel
.sidepanel-section{
    height:100%;
    display:flex;
    flex-direction:column;
}

.sidepanel-section-container{
    height:90%;
}

.preview-section{
    height: 90%;
    overflow-y: auto;
}

.sidepanel-section-buttons{
    height:10%;
    display:flex;
    justify-content:center;
    align-items:center;
}
.campaign-schedule-icon{
    background: url($url-prefix + "/images/meetingsicon.svg") no-repeat 0px 0px;
    background-size: 21px;
}
.campaign-reminder-icon{
    background: url($url-prefix + "/images/reminder.svg") no-repeat 0px 0px;
    background-size: 21px;
}
.campaign-cancel-icon{
    background: url($url-prefix + "/images/SubscriptionIcons/Send Cancel.svg") no-repeat 0px 0px;
    background-size: 18px;
}
.mm-logo-white {
    background: url($url-prefix + "/images/mm-logo-white.svg") no-repeat center center;
}
.cmp-green-tick {
    background: url($url-prefix + "/images/cmp-green-tick-icon.svg") no-repeat center center;
}
.reset-icon-pas{
    background: url($url-prefix + "/images/reset-icon-pas.svg") no-repeat center center;
}
.white-tick-pas{
    background: url($url-prefix + "/images/check-white-mark-pas.svg") no-repeat center center;
}
