.mm-tooltip {
    display: inline-block;
    z-index: 19020;
    position: fixed;
    background-color: #ebf4fb;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    color: black;
    padding: 4px 6px;
}
.react-tooltip-lite {
    // background: white !important;
    min-width: unset !important;
    max-width: 500px !important;
    border-radius: 5px;
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.4) !important;
}

.react-tooltip-lite-arrow {
    // border-color: white;
}
