@import "../defaults.scss";

div.dropdown-container {
	display: inline-block;
	position: relative;
	width: 100%;
}
.dropdown-container #csTaNotesCalls {
	border: 1px solid #dfdfdf;
}

.axd-down-arrow-default-visible{
	content: "";
	width: 20px;
	height: 100%;
	position: absolute;
	right: 0px;
	bottom: 2px;
	display: inline-block;
	background: url($url-prefix + "/images/icon-down-arrow.svg");
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	background-size: 13px;
}
.axd-ext-link-default-visible{
	content: "";
	width: 20px;
	height: 100%;
	position: absolute;
	right: 18px;
	bottom: 2px;
	display: inline-block;
	background: url($url-prefix + "/images/external-link.png");
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	background-size: 13px;
}

.add-option-icon{
	position: absolute;
    right: 20px;
    top: 5px;
	cursor: pointer;
}

.axd-down-arrow:after {
	content: "";
	width: 20px;
	height: 100%;
	position: absolute;
	right: 0px;
	bottom: 2px;
	display: inline-block;
	background: url($url-prefix + "/images/icon-down-arrow.svg");
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	background-size: 13px;
}

.dropdown-value {
	cursor: pointer;
}

div.dropdown-value input[type="text"],
div.dropdown-value .dd-display {
	border: 1px solid #dfdfdf;
	padding-right: 20px;
	font-size: 14px;
	font-weight: 300;
	line-height: 17px;
	cursor: pointer;
}

div.dropdown-value input[type="text"]:disabled {
	cursor: not-allowed; // if disabled, cursor should be not-allowed
}

.dropdown {
	display: block;
	max-height: 280px;
	min-width: auto;
	width: 100%;
	overflow: auto;
	position: absolute;
	top: 25px;
	/*left: 0px;*/
	z-index: 19000;
	width: 100%;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.16);

	&:empty {
		border: none;
	}
}

.dropdown li {
	list-style: none;
	padding: 5px;
	color: #000;
	cursor: pointer;
	padding: 5px;
	min-height: 23px;
	line-height: 22px;
}

// .dropdown li:hover:not(.no-hover),
.dropdown li.focused {
	background-color: #dff0ff;
}

.dropdown li.selected {
	background-color: #add6f1;
}

li.no-hover {
	color: #333;
	cursor: default;
}

.novalue-border {
	border: 1px solid red;
}
