.loading {
  background-color: #e5e5e5;
  border-radius: 3px;
  padding: 8px;
  text-align: center;
  color: #666;
  min-width: 100px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
  background: rgba(255, 255, 255, 0.8);
}